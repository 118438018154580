<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import { useCart } from '/~/composables/cart'
import { useCheckout } from '/~/composables/checkout'
import CheckoutCard from './checkout-card.vue'

export default {
  name: 'checkout-payments',
  components: {
    CheckoutCard,
    BaseIcon,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    const { cartPaymentMethodSelectionDisabled } = useCart()
    const { selectedPaymentInfo } = useCheckout()

    return {
      cartPaymentMethodSelectionDisabled,
      selectedPaymentInfo,
    }
  },
}
</script>

<template>
  <div>
    <h2 class="mb-2.5 text-xl">Pay with</h2>
    <checkout-card v-if="isLoading" :loading="isLoading" />
    <checkout-card
      v-else-if="cartPaymentMethodSelectionDisabled"
      v-bind="selectedPaymentInfo"
      icon-box-size="w-11 h-11"
      icon-class="text-gray-400"
      content-class="text-gray-400"
      class="cursor-not-allowed"
    />
    <router-link v-else :to="{ name: 'purchase-checkout-payment-methods' }">
      <checkout-card v-bind="selectedPaymentInfo" icon-box-size="w-11 h-11">
        <base-icon svg="plain/chevron-right" size="sm" />
      </checkout-card>
    </router-link>
  </div>
</template>
