<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BasePlaceholder from '/~/components/base/placeholder/base-placeholder.vue'

export default {
  name: 'checkout-card-item',
  components: {
    BasePlaceholder,
    BaseIcon,
  },
  props: {
    icon: {
      type: String,
      default: '',
    },
    hideShadow: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isDefault: {
      type: Boolean,
      default: false,
    },
    iconClass: {
      type: String,
      default: '',
    },
    iconSize: {
      type: [String, Number],
      default: 'lg',
    },
    title: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    contentClass: {
      type: [String, Object, Array],
      default: '',
    },
    iconBoxSize: {
      type: String,
      default: '',
    },
  },
}
</script>

<template>
  <div
    class="relative flex min-h-20 items-center justify-between bg-white px-6 py-[15px] text-default"
    :class="[!hideShadow && 'rounded-lg']"
  >
    <div v-if="loading" class="w-full">
      <div class="w-3.5">
        <base-placeholder :fill="true" rows="1" type="text" />
      </div>
      <div class="w-1.5">
        <base-placeholder :fill="true" rows="1" type="text" />
      </div>
    </div>
    <template v-else>
      <div
        v-if="icon"
        class="mr-5 flex shrink-0 items-center justify-center rounded-full bg-white"
        :class="[!iconBoxSize && 'h-12 w-12', iconBoxSize, iconClass]"
      >
        <base-icon :svg="icon" :size="iconSize" />
      </div>

      <div class="mr-2.5 grow" :class="contentClass">
        <div class="flex">
          <div class="mr-5 font-bold">
            {{ title }}
          </div>

          <div
            v-if="isDefault"
            class="inline-flex items-center text-sm font-semibold leading-6 text-fg-success"
          >
            <base-icon svg="plain/check" size="sm" class="mr-2.5" />
            Default
          </div>
        </div>

        <div v-if="text" class="text-sm text-eonx-neutral-600">
          {{ text }}
        </div>

        <slot name="content" />
      </div>

      <div
        v-if="$slots.default"
        class="ml-5 flex h-12 items-center justify-center"
      >
        <slot />
      </div>
    </template>
  </div>
</template>
